html,
body {
  background-color: #0d0d0d;
  font-family: 'Open Sans', sans-serif!important;
  letter-spacing: -.02rem;
  color: white;
}

.App {
  position: absolute;
  font-size: calc(10px + 2vmin);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.Card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: inherit;
  padding: 64px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.card {
  background-color: inherit;
}

.Uploader {
  background: rgb(0,219,222);
  background: linear-gradient(90deg, rgba(0,219,222,0.2) 0%, rgba(252,0,255,0.2) 100%);
  border-radius: 0.5rem;
  padding: 3rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.progress { height: 1.5rem; }

.main {
  padding-top: 5rem!important;
  padding-bottom: 3rem!important;
}

.full-height { height: 100%; display: flex; }

.middle-align { height: 100%; display: flex; align-items: center; justify-content: center; }

.login-form {
  background-color: rgba(0, 0, 0, 0.01);
  text-align: center;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  padding: 1rem;
}

.form-group {
  text-align: left;
}

.popover {
  font-family: inherit;
  letter-spacing: inherit;
}

.popover-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
  padding: 0;
  border-radius: 0 0 .3rem .3rem;
}

.upload-enter {
  opacity: 0.01;
}

.upload-enter.upload-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.upload-leave {
  opacity: 1;
}

.upload-leave.upload-leave-active {
  opacity: 0.01;
  transition: all 300ms ease-in;
}

.card-light-green {
  border-color: rgba(171, 200, 199, 0.25);
  border-width: .1rem;
}

.bg-light-green {
  background: #ABC8C7;
}

.bg-db {
  background: #0d0d0d;
}

.bg-success {
  background-color: #0D5257!important;
}

.bg-uploading {
  color: #0D5257;
  background-color: #ABC8C7!important;
}

.bg-error {
  background-color: #ff0000!important;
}

.btn-xs {
  line-height: .5rem!important;
  padding: .25rem;
  font-size: .7rem;
  float: right;
}

.btn-sm {
  line-height: 1.3rem!important;
}

.btn-light-green {
  background: #ABC8C7;
  color: #0D5257;
}

.btn-light-green:hover {
  color: rgb(27, 111, 117);
}

.dark-grey {
  background: #202020;
  color: #ffffff;
}

.dark-grey > .arrow:after {
  border-top-color: #202020;
}

.dark-grey-dropdown {
  background: #202020;
  color: #ffffff;
  line-height: 1.3rem!important;
  padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.dark-grey-dropdown:hover {
  color: #a5a5a5;
}

.btn-dark-grey {
  background: #202020;
  color: #ffffff;
}
.btn-dark-grey:hover {
  color: #a5a5a5;
}

.sign-in-button {
  width: 100%;
  margin-top: 1rem;
}

.purple-gradient {
  background-color: #2c0067;
  background-image: linear-gradient(19deg, #2c0067 0%, #B721FF 100%);
}

.text-clip {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.list-group-item-google {
  color: white;
  background-color: #4285F4;
}

.list-group-item-google:hover {
  color: white;
  background-color: #3275E4;
}

.list-group-item-microsoft {
  color: #5e5e5e;
  background-color: #ffffff;
}

.list-group-item-microsoft:hover { 
  color: #5e5e5e;
  background-color: #f0f0f0;
  border-color: #5e5e5e;
}

.list-group-item-amazon {
  color: white;
  background-color: #FF9900;
}

.list-group-item-amazon:hover {
  color: white;
  background-color: #EF8900;
  border-color: #FF9900;
}

.list-group-item-untrusted {
  color: white;
  font-weight: 400;
  background-color: #fc00ff;
}

.list-group-item-untrusted:hover {
  color: white;
  background-color: #fb00ff46;
}